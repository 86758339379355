import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
  GATSBY_GIOTRIF_PEF_KEY,
  GATSBY_GIOTRIF_PEF_VALUE,
  GATSBY_GIOTRIF_MA_KEY,
  GATSBY_GIOTRIF_MA_VALUE,
  GATSBY_GIOTRIF_AVAILMENT_KEY,
  GATSBY_GIOTRIF_AVAILMENT_VALUE,
} from "gatsby-env-variables"

export const setEncryptedKey = module => {
  let key = ""
  let value = ""

  switch (module) {
    case "giotrif-patient":
      key = GATSBY_GIOTRIF_PEF_KEY
      value = GATSBY_GIOTRIF_PEF_VALUE
      break
    case "giotrif-medicine-assistance":
      key = GATSBY_GIOTRIF_MA_KEY
      value = GATSBY_GIOTRIF_MA_VALUE
      break
    case "giotrif-medicine-availment":
      key = GATSBY_GIOTRIF_AVAILMENT_KEY
      value = GATSBY_GIOTRIF_AVAILMENT_VALUE
      break
    case "hope-financial-assessment":
      key = GATSBY_FINANCIAL_ASSESSMENT_KEY
      value = GATSBY_FINANCIAL_ASSESSMENT_VALUE
      break
    default:
      key = ""
      value = ""
      break
  }

  sessionStorage.setItem(key, value)
}

export const getEncryptedKey = module => {
  let key = "KEY"
  let value = "VALUE"

  switch (module) {
    case "giotrif-patient":
      key = GATSBY_GIOTRIF_PEF_KEY
      value = GATSBY_GIOTRIF_PEF_VALUE
      break
    case "giotrif-financial-assessment":
    case "giotrif-medicine-assistance":
      key = GATSBY_GIOTRIF_MA_KEY
      value = GATSBY_GIOTRIF_MA_VALUE
      break
    case "giotrif-medicine-availment":
      key = GATSBY_GIOTRIF_AVAILMENT_KEY
      value = GATSBY_GIOTRIF_AVAILMENT_VALUE
      break
    case "hope-financial-assessment":
      key = GATSBY_FINANCIAL_ASSESSMENT_KEY
      value = GATSBY_FINANCIAL_ASSESSMENT_VALUE
      break
    default:
      key = ""
      value = ""
      break
  }

  return sessionStorage.getItem(key) !== value
}

export const removeEncryptedKey = module => {
  let key = "KEY"

  switch (module) {
    case "giotrif-patient":
      key = GATSBY_GIOTRIF_PEF_KEY
      break
    case "giotrif-financial-assessment":
    case "giotrif-medicine-assistance":
      key = GATSBY_GIOTRIF_MA_KEY
      break
    default:
      key = ""
      break
  }

  return sessionStorage.removeItem(key)
}
